<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { caregiverDocumentsApi, type CaregiverDocument } from '@/api/caregiver-documents';
import type { AxiosError } from 'axios';

const { t } = useI18n();

const emit = defineEmits(['close']);

interface Props {
  caregiverDocument: CaregiverDocument;
}

const props = defineProps<Props>();

const { handleSubmit } = useForm();

const queryClient = useQueryClient();

const { mutate, isError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => caregiverDocumentsApi.destroy(props.caregiverDocument.id),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['caregiverDocuments', props.caregiverDocument.caregiverId] });
      emit('close');
    },
  },
);

const onSubmit = handleSubmit(() => mutate());
</script>
<template>
  <v-card
    class="v-col-12 v-col-md-10 pa-4"
    flat
  >
    <v-card-title>
      {{ t('caregiverDocumentDestroyForm.title') }}
    </v-card-title>
    <v-form
      class="w-100 ma-0"
      @submit.prevent="onSubmit"
    >
      <v-card flat>
        <v-card-text>
          {{ t('caregiverDocumentDestroyForm.confirmation') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            text="Cerrar"
            @click="$emit('close')"
          />
          <v-spacer />
          <v-btn
            color="red"
            type="submit"
          >
            {{ t('caregiverDocumentDestroyForm.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-alert
      v-if="isError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ t('userSession.defaultError') }}
    </v-alert>
  </v-card>
</template>
