<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { caregiversApi } from '@/api/caregivers';
import { patientsApi } from '@/api/patients';
import { shiftsApi } from '@/api/shifts';
import { useFilters } from '@/composables/useFilters';
import { useQuery } from '@tanstack/vue-query';
import { useShiftsStore } from '@/stores/shifts';
import ShiftShow from './actions/shift-show.vue';
import ShiftForm from './actions/shift-form.vue';
import ShiftBatchDestroyForm from './shift-batch-destroy-form.vue';
import ShiftRecurrentCreateForm from './shift-recurrent-create-form.vue';
import ShiftsListSummary from './shifts-list-summary.vue';

interface Props {
  patientId: number,
  fromForm: boolean,
}

const props = defineProps<Props>();

const store = useShiftsStore();

const today = new Date();
const yesterday = new Date();
yesterday.setDate(today.getDate() - 1);

const startDateTimeFilterOptions = [
  {
    title: 'Diaria',
    value: 'today',
  },
  {
    title: 'Semanal',
    value: 'weekly',
  },
  {
    title: 'Mensual',
    value: 'monthly',
  },
];

const groupByClassOptions = [
  {
    title: 'Por Profesional',
    value: 'caregiver',
  },
  {
    title: 'Por Paciente',
    value: 'patient',
  },
];

function initialPatientId() {
  if (props.fromForm) return props.patientId;

  store.currentPatientId = store.currentPatientId ||
    (store.currentCaregiverId ? undefined : props.patientId) || undefined;

  return store.currentPatientId;
}

const currentStartDateTimeFilter = ref<string>(store.currentStartDateTimeFilter || 'weekly');
const currentPatientId = ref<number | undefined>(initialPatientId());
const currentCaregiverId = ref<number | undefined>(store.currentCaregiverId || undefined);
const currentOffset = ref<number>(store.currentOffset || 0);
const currentGroupByClass = ref<string>(store.currentGroupByClass || 'caregiver');

const filters = ref<{
  patientIdEq: undefined | number,
  caregiverIdEq: undefined | number,
  startDateTimeFilter: undefined | string,
  offset: number,
  groupByClass: string,
}>({
  patientIdEq: currentPatientId.value,
  caregiverIdEq: currentCaregiverId.value,
  startDateTimeFilter: currentStartDateTimeFilter.value,
  offset: currentOffset.value,
  groupByClass: currentGroupByClass.value,
});
const queryParams = useFilters(filters.value);

function updateOffset(offset: number) {
  filters.value.offset += offset;
}

function resetOffset() {
  filters.value.offset = 0;
}

const { data: patients, isPending: isPatientsPending } = useQuery({
  queryKey: ['patients'],
  queryFn: () => patientsApi.index(),
});

const { data: caregivers, isPending: isCaregiversPending } = useQuery({
  queryKey: ['caregivers'],
  queryFn: () => caregiversApi.index(),
});

const {
  data: shiftsAsCalendar,
  refetch: refetchAsCalendar,
  isPending: isShiftsAsCalendarPending,
  isFetching: isShiftsAsCalendarFetching,
} = useQuery({
  queryKey: ['shiftsAsCalendar'],
  queryFn: () => shiftsApi.indexAsCalendar(queryParams.value),
});

const drawer = ref(true);
const rail = ref(false);

const currentPatient = computed(() => {
  if (!filters.value.patientIdEq) return undefined;

  return patients.value?.find((patient) => patient.id === filters.value.patientIdEq);
});

const currentCaregiver = computed(() => {
  if (!filters.value.caregiverIdEq) return undefined;

  return caregivers.value?.find((caregiver) => caregiver.id === filters.value.caregiverIdEq);
});

function checkIfStoreIsEqualToFilters() {
  return (
    store.currentPatientId === filters.value.patientIdEq &&
    store.currentCaregiverId === filters.value.caregiverIdEq &&
    store.currentStartDateTimeFilter === filters.value.startDateTimeFilter &&
    store.currentOffset === filters.value.offset &&
    store.currentGroupByClass === filters.value.groupByClass
  );
}

function updateStoreFromFilters() {
  store.currentPatientId = filters.value.patientIdEq;
  store.currentCaregiverId = filters.value.caregiverIdEq;
  store.currentGroupByClass = filters.value.groupByClass;
  if (store.currentStartDateTimeFilter === filters.value.startDateTimeFilter) {
    store.currentOffset = filters.value.offset;
  } else {
    store.currentStartDateTimeFilter = filters.value.startDateTimeFilter;
    filters.value.offset = 0;
    store.currentOffset = 0;
  }
}

watch(filters, () => {
  if (checkIfStoreIsEqualToFilters()) return;

  updateStoreFromFilters();
  refetchAsCalendar();
}, { deep: true });

watch(patients, () => {
  if (filters.value.patientIdEq && !currentPatient.value) {
    filters.value.patientIdEq = undefined;
  }
});

watch(caregivers, () => {
  if (filters.value.caregiverIdEq && !currentCaregiver.value) {
    filters.value.caregiverIdEq = undefined;
  }
});

const isGeneralViewActive = computed(() => !currentPatient.value && !currentCaregiver.value);
const isGeneralViewActiveAndGroupByClassIsPatient = computed(
  () => isGeneralViewActive.value && filters.value.groupByClass === 'patient',
);
const isGeneralViewActiveAndGroupByClassIsCaregiver = computed(
  () => isGeneralViewActive.value && filters.value.groupByClass === 'caregiver',
);

const calendarHeaders = computed(() => {
  const baseHeaders = [];
  if (currentPatient.value || isGeneralViewActiveAndGroupByClassIsCaregiver.value) {
    baseHeaders.push({ title: 'Profesional', value: 'caregiver.fullName', fixed: true });
  } else if (currentCaregiver.value || isGeneralViewActiveAndGroupByClassIsPatient.value) {
    baseHeaders.push({ title: 'Paciente', value: 'patient.fullName', fixed: true });
  }

  baseHeaders.push(...(shiftsAsCalendar.value?.headers || []));

  return baseHeaders;
});

const slotNamesWithDate = computed(() => {
  const slotHeaders = shiftsAsCalendar.value?.headers || [];

  return slotHeaders.map((header) => header.children.map((child) => ({ date: header.date, name: child.value }))).flat();
});

const title = computed(() => {
  if (currentPatient.value) {
    return `Turnos de Paciente ${currentPatient.value.fullName}`;
  } else if (currentCaregiver.value) {
    return `Turnos de Profesional ${currentCaregiver.value.fullName}`;
  }

  return 'Turnos';
});

const shiftAsCalendarByPatientNotRefreshedYet = computed(
  () => shiftsAsCalendar.value?.groupByClass === 'patient',
);

const caregiversByCurrentPatient = computed(() => {
  if (!currentPatient.value || shiftAsCalendarByPatientNotRefreshedYet.value || isShiftsAsCalendarPending.value) {
    return caregivers.value;
  }
  const caregiversIds = shiftsAsCalendar.value?.shifts.map(
    (caregiverShifts) => caregiverShifts.caregiver?.id,
  ) || [];

  return caregivers.value?.filter((caregiver) => caregiversIds.includes(caregiver.id));
});

const caregiverWithoutShifts = ref(false);
watch(caregiversByCurrentPatient, () => {
  if (!filters.value.caregiverIdEq || isCaregiversPending.value) return;

  const caregiverInstance = caregiversByCurrentPatient.value?.find(
    (caregiver) => caregiver.id === filters.value.caregiverIdEq,
  );
  if (!caregiverInstance) {
    filters.value.caregiverIdEq = undefined;
    caregiverWithoutShifts.value = true;
  }
});

const patientsByCurrentCaregiver = computed(() => {
  if (!currentCaregiver.value || currentPatient.value) return patients.value;

  const patientsIds = shiftsAsCalendar.value?.shifts.map(
    (patientShifts) => patientShifts.patient?.id,
  ) || [];

  return patients.value?.filter((patient) => patientsIds.includes(patient.id));
});

function getTimesFromSlotName(slotName: string) {
  if (slotName.includes('day')) {
    return { startTime: '08:00', endTime: '20:00' };
  } else if (slotName.includes('night')) {
    return { startTime: '20:00', endTime: '08:00' };
  }

  return {};
}

// eslint-disable-next-line complexity
function generateShiftForForm(item = undefined, slotName = undefined) {
  let shift: { patientId?: number, caregiverId?: number, startTime?: string, endTime?: string } = {};
  if (currentPatient.value) {
    shift.patientId = currentPatient.value.id;
    if (item) shift.caregiverId = item.caregiver.id;
  } else if (currentCaregiver.value) {
    shift.caregiverId = currentCaregiver.value.id;
    if (item) shift.patientId = item.patient.id;
  } else if (filters.value.groupByClass === 'caregiver') {
    if (item) shift.caregiverId = item.caregiver.id;
  } else if (filters.value.groupByClass === 'patient') {
    if (item) shift.patientId = item.patient.id;
  }

  if (slotName) shift = { ...shift, ...getTimesFromSlotName(slotName) };

  return shift;
}

const actionSuccess = ref(false);
const createMenuActive = ref(false);

const skeletonLoaderActive = computed(
  () => isPatientsPending.value || isCaregiversPending.value || isShiftsAsCalendarPending.value,
);

const showSummary = computed(
  () => !isShiftsAsCalendarPending.value && filters.value.startDateTimeFilter === 'monthly',
);

const shiftsNewUrl = computed(() => {
  if (currentPatient.value) {
    return `/shifts/new?patient_id=${currentPatient.value.id}`;
  }

  return '/shifts/new';
});
</script>
<template>
  <v-skeleton-loader
    :loading="skeletonLoaderActive"
    type="table"
    class="w-100"
  >
    <v-navigation-drawer
      v-model="drawer"
      :rail="rail"
      permanent
      @click="rail = false"
    >
      <v-list-item
        title="Filtros"
        nav
      >
        <template #prepend>
          <v-btn
            icon="mdi-filter"
            variant="text"
            slim
            size="small"
            @click.stop="rail = !rail"
          />
        </template>
        <template #append>
          <v-btn
            icon="mdi-chevron-left"
            variant="text"
            @click.stop="rail = !rail"
          />
        </template>
      </v-list-item>
      <v-divider />
      <v-list-item :class="rail ? 'd-sr-only mt-4' : 'mt-4'">
        <p class="mb-2 font-weight-bold">
          Paciente
        </p>
        <v-autocomplete
          v-model="filters.patientIdEq"
          :items="patientsByCurrentCaregiver"
          item-title="fullName"
          item-value="id"
          variant="outlined"
          bg-color="white"
          class="text-subtitle-1"
          clearable
          persistent-clear
          no-data-text="Sin pacientes asignados"
        />
      </v-list-item>
      <v-list-item :class="rail ? 'd-sr-only' : ''">
        <p class="mb-2 font-weight-bold">
          Profesional
        </p>
        <v-autocomplete
          v-model="filters.caregiverIdEq"
          :items="caregiversByCurrentPatient"
          item-title="fullName"
          item-value="id"
          variant="outlined"
          bg-color="white"
          clearable
          persistent-clear
          no-data-text="Sin profesionales asignados"
        />
      </v-list-item>
      <v-list-item :class="rail ? 'd-sr-only' : ''">
        <p class="mb-2 font-weight-bold">
          Vista
        </p>
        <v-select
          v-model="filters.startDateTimeFilter"
          :items="startDateTimeFilterOptions"
          item-title="title"
          item-value="value"
          variant="outlined"
          bg-color="white"
          class="text-subtitle-1"
          persistent-clear
        />
      </v-list-item>
      <v-list-item
        v-if="isGeneralViewActive"
        :class="rail ? 'd-sr-only' : ''"
      >
        <p class="mb-2 font-weight-bold">
          Visión General
        </p>
        <v-select
          v-model="filters.groupByClass"
          :items="groupByClassOptions"
          item-title="title"
          item-value="value"
          variant="outlined"
          bg-color="white"
          class="text-subtitle-1"
          persistent-clear
        />
      </v-list-item>
    </v-navigation-drawer>
    <v-card class="w-100">
      <v-data-table
        :headers="calendarHeaders"
        :items="shiftsAsCalendar?.shifts || []"
        :items-per-page="-1"
        :loading="isShiftsAsCalendarFetching"
        :loading-text="'Cargando turnos...'"
        no-data-text="No hay turnos asignados"
      >
        <template #top>
          <v-toolbar
            color="white"
            flat
          >
            <v-toolbar-title class="font-weight-bold">
              {{ title }}
            </v-toolbar-title>
          </v-toolbar>
          <v-toolbar
            color="white"
            flat
          >
            <v-btn
              variant="outlined"
              size="small"
              :disabled="isShiftsAsCalendarFetching"
              @click="resetOffset()"
            >
              Hoy
            </v-btn>
            <v-btn
              icon="mdi-chevron-left"
              :disabled="isShiftsAsCalendarFetching"
              @click="updateOffset(-1)"
            />
            <v-btn
              icon="mdi-chevron-right"
              :disabled="isShiftsAsCalendarFetching"
              @click="updateOffset(1)"
            />
            <p class="w-100">
              {{ shiftsAsCalendar?.title }}
            </p>
            <v-spacer />
            <div class="justify-end mr-4 d-flex w-100 align-center">
              <v-btn class="mb-2 text-white border bg-primary">
                Agregar
                <v-menu
                  v-model="createMenuActive"
                  activator="parent"
                  transition="scale-transition"
                  location="top"
                >
                  <v-list>
                    <v-dialog
                      max-width="800"
                      scrollable
                    >
                      <template #activator="{ props: activatorProps }">
                        <v-list-item v-bind="activatorProps">
                          <v-list-item-title>Recurrente</v-list-item-title>
                        </v-list-item>
                      </template>
                      <template #default="{ isActive }">
                        <shift-recurrent-create-form
                          :patient="currentPatient"
                          :caregiver="currentCaregiver"
                          @close="isActive.value = false; createMenuActive = false"
                          @success="actionSuccess = true"
                          @refetch="refetchAsCalendar"
                        />
                      </template>
                    </v-dialog>
                    <v-list-item :href="shiftsNewUrl">
                      <v-list-item-title>Manual</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-btn>
              <v-dialog
                v-if="currentPatient"
                max-width="500"
                scrollable
              >
                <template #activator="{ props: activatorProps }">
                  <v-btn
                    v-bind="activatorProps"
                    class="mb-2 ml-4 text-red"
                    variant="outlined"
                  >
                    Detener
                  </v-btn>
                </template>
                <template #default="{ isActive }">
                  <shift-batch-destroy-form
                    :patient="currentPatient"
                    @close="isActive.value = false"
                    @success="actionSuccess = true"
                    @refetch="refetchAsCalendar"
                  />
                </template>
              </v-dialog>
            </div>
          </v-toolbar>
        </template>
        <template #headers="{ headers: headersInfo }">
          <tr>
            <template
              v-for="(header, index) in headersInfo[0]"
              :key="header.title"
            >
              <th
                v-if="index === 0"
                colspan="1"
                class="font-weight-bold v-data-table-column--fixed v-data-table-column--last-fixed"
              >
                {{ header.title }}
              </th>
              <th
                v-else
                colspan="2"
                :class="header.headerProps?.class"
              >
                <v-tooltip
                  v-if="header.headerProps?.holiday"
                  activator="parent"
                  location="top"
                >
                  {{ header.headerProps?.holiday.title }}
                </v-tooltip>
                <v-dialog
                  max-width="800"
                  scrollable
                >
                  <template #activator="{ props: activatorProps }">
                    <v-btn
                      v-bind="activatorProps"
                      variant="text"
                      size="small"
                      class="ma-0 pa-3 w-100 h-100 text-body-2 font-weight-bold"
                    >
                      {{ header.title }}
                    </v-btn>
                  </template>
                  <template #default="{ isActive }">
                    <shift-form
                      :shift="generateShiftForForm()"
                      :date="header.date"
                      mode="new"
                      @close="isActive.value = false"
                      @success="actionSuccess = true"
                      @refetch="refetchAsCalendar"
                    />
                  </template>
                </v-dialog>
              </th>
            </template>
          </tr>
        </template>
        <template
          v-for="slot in slotNamesWithDate"
          #[`item.${slot.name}`]="{ item }"
          :key="slot.name"
        >
          <div
            v-if="item[slot.name]"
            class="d-flex flex-column w-100 h-100"
          >
            <v-dialog
              v-for="shift in item[slot.name]"
              :key="shift.id"
              max-width="800"
              scrollable
            >
              <template #activator="{ props: activatorProps }">
                <v-btn
                  v-bind="activatorProps"
                  variant="text"
                  size="small"
                  class="ma-0 pa-0 w-100 flex-grow-1"
                >
                  {{ shift.humanLetter }}
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    {{ shift.humanTime }}
                  </v-tooltip>
                </v-btn>
              </template>
              <template #default="{ isActive }">
                <shift-show
                  :shift="shift"
                  @close="isActive.value = false"
                  @success="actionSuccess = true"
                  @refetch="refetchAsCalendar"
                />
              </template>
            </v-dialog>
          </div>
          <v-dialog
            v-else
            max-width="800"
            scrollable
          >
            <template #activator="{ props: activatorProps }">
              <v-btn
                v-bind="activatorProps"
                variant="text"
                size="small"
                class="ma-0 pa-0 w-100 h-100"
              />
            </template>
            <template #default="{ isActive }">
              <shift-form
                :shift="generateShiftForForm(item, slot.name)"
                :date="slot.date"
                mode="new"
                @close="isActive.value = false"
                @success="actionSuccess = true"
                @refetch="refetchAsCalendar"
              />
            </template>
          </v-dialog>
        </template>
        <template #bottom>
          <div class="justify-end d-flex border-t-sm">
            <p class="mx-8 my-2 text-caption">
              <v-icon
                icon="mdi-information"
                class="mb-1"
              />
              ¿Qué significa D, N y P?
              <v-tooltip
                activator="parent"
                location="bottom"
              >
                <p>D = Turno día de 08:00 a 20:00</p>
                <p>N = Turno noche de 20:00 a 08:00</p>
                <p>P = Turno personalizado</p>
              </v-tooltip>
            </p>
          </div>
        </template>
      </v-data-table>
      <v-snackbar
        v-model="actionSuccess"
        :timeout="2000"
        color="success"
        rounded="pill"
      >
        <v-icon
          icon="mdi-check-circle"
          class="mr-4"
        />
        Cambios guardados exitosamente!
      </v-snackbar>
      <v-snackbar
        v-model="caregiverWithoutShifts"
        :timeout="4000"
        color="warning"
        rounded="pill"
      >
        <v-icon
          icon="mdi-information-outline"
          class="mr-2"
        />
        El profesional seleccionado no tiene turnos con el paciente en este período.
      </v-snackbar>
    </v-card>
    <shifts-list-summary
      v-if="showSummary"
      :summary="shiftsAsCalendar?.summary || []"
      :total-summary="shiftsAsCalendar?.totalSummary ||
        { totalShifts: 0, totalPrice: 0, totalCost: 0, totalProfit: 0, margin: 0 }"
      class="mt-8"
    />
  </v-skeleton-loader>
</template>
