<script setup lang="ts">
import { ref } from 'vue';
import { type Shift } from '@/api/shifts';
import ShiftForm from './shift-form.vue';
import ShiftDestroyForm from './shift-destroy-form.vue';
import ShiftShowGeneral from './shift-show-general.vue';
import ShiftShowAmounts from './shift-show-amounts.vue';
import ShiftShowReportImages from './shift-show-report-images.vue';
import ShiftShowSummary from './shift-show-summary.vue';

const props = defineProps<{ shift: Shift }>();

const emit = defineEmits(['close', 'refetch', 'success']);

const view = ref('show');

const tab = ref('general');

function waitBeforeCloseAndRefetch() {
  const waitMs = 1500;
  setTimeout(() => {
    emit('refetch');
    emit('close');
  }, waitMs);
}
</script>
<template>
  <v-card
    v-if="view === 'show'"
    class="pa-4"
  >
    <v-card-title class="mt-2 d-flex justify-space-between">
      Información del Turno
      <div>
        <v-btn
          icon="mdi-pencil"
          color="primary"
          size="small"
          variant="outlined"
          class="mr-4"
          @click="view = 'update'"
        />
        <v-btn
          icon="mdi-delete"
          color="red"
          size="small"
          variant="outlined"
          @click="view = 'destroy'"
        />
      </div>
    </v-card-title>
    <v-card>
      <v-tabs
        v-model="tab"
        color="primary"
      >
        <v-tab
          value="general"
          class="rounded-pill text-body-1"
        >
          General
        </v-tab>
        <v-tab
          value="amounts"
          class="rounded-pill text-body-1"
        >
          Precios
        </v-tab>
        <v-tab
          value="reports"
          class="rounded-pill text-body-1"
        >
          Registros
        </v-tab>
        <v-tab
          value="summary"
          class="rounded-pill text-body-1"
        >
          Resumen
        </v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item value="general">
          <shift-show-general :shift="props.shift" />
        </v-window-item>
        <v-window-item value="amounts">
          <shift-show-amounts :shift="props.shift" />
        </v-window-item>
        <v-window-item value="reports">
          <shift-show-report-images :shift="props.shift" />
        </v-window-item>
        <v-window-item value="summary">
          <shift-show-summary :shift="props.shift" />
        </v-window-item>
      </v-window>
    </v-card>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text="Cerrar"
        @click="emit('close')"
      />
    </v-card-actions>
  </v-card>
  <shift-form
    v-if="view === 'update'"
    :shift="props.shift"
    :close-button-text="'Volver'"
    :date="props.shift.startDatetime"
    @close="view = 'show'"
    @success="emit('success')"
    @refetch="emit('refetch')"
  />
  <shift-destroy-form
    v-if="view === 'destroy'"
    :shift-id="props.shift.id"
    :close-button-text="'Volver'"
    @close="waitBeforeCloseAndRefetch()"
    @success="emit('success')"
    @refetch="emit('refetch')"
  />
</template>
