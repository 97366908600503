<script setup lang="ts">
import { nextTick, ref, watch } from 'vue';

interface Header {
  title: string;
  value: string;
  headerProps?: { class: string };
  cellProps?: { class: string };
}

interface Shift {
  day: string;
  dayShift: boolean;
  nightShift: boolean;
  customShift: boolean;
}

interface ShiftItem {
  shiftType: string;
  name: string;
  [key: `day${number}`]: boolean;
}

const props = defineProps({
  firstDay: {
    type: String,
    required: true,
  },
  caregiverId: {
    type: Number,
    required: true,
  },
  blockedShifts: {
    type: Array<Shift>,
    default: () => [],
  },
  showShifts: {
    type: Array<boolean>,
    default: () => [true, true, true],
  },
});

const days = 7;
const currentDay = ref(1);
const lastDay = ref(days);
const headers = ref<Header[]>([{ title: 'Turno', value: 'name' }]);

function checkIfIsWeekend(date: Date) {
  const SATURDAY = 6;
  const SUNDAY = 0;

  return date.getDay() === SATURDAY || date.getDay() === SUNDAY;
}

function generateDayHeaders() {
  const INITIAL_DAY_OFFSET = 2;

  for (let day = 1; day <= days; day++) {
    const dayDate = new Date(props.firstDay);
    const dayOffset = currentDay.value + day - INITIAL_DAY_OFFSET;
    dayDate.setDate(dayDate.getDate() + dayOffset);

    const dayTitle = dayDate.toLocaleDateString('es', { weekday: 'short', day: 'numeric', month: 'numeric' });
    headers.value.push(
      {
        title: dayTitle,
        value: `day${dayOffset + 1}`,
        headerProps: { class: checkIfIsWeekend(dayDate) ? 'bg-blue-grey-lighten-4' : '' },
        cellProps: { class: checkIfIsWeekend(dayDate) ? 'bg-blue-grey-lighten-4' : '' },
      });
  }
}

watch(lastDay, () => {
  generateDayHeaders();
});

const items = ref<ShiftItem[]>([]);
function setItems() {
  if (props.showShifts[0]) items.value.push({ shiftType: 'day', name: 'Día' });
  if (props.showShifts[1]) items.value.push({ shiftType: 'night', name: 'Noche' });
  // eslint-disable-next-line no-magic-numbers
  if (props.showShifts[2]) items.value.push({ shiftType: 'custom', name: 'Personalizado' });
}
setItems();

function addDaysToItems() {
  items.value.forEach(shiftItem => {
    for (let day = 1; day <= lastDay.value; day++) {
      if (shiftItem[`day${day}`] !== undefined) continue;
      shiftItem[`day${day}`] = false;
    }
  });
}

watch(() => props.showShifts, () => {
  items.value = [];
  setItems();
  addDaysToItems();
}, { deep: true });

async function nextWeek() {
  currentDay.value += days;
  lastDay.value += days;
  addDaysToItems();
  await nextTick();
}

const caregiverShifts = defineModel<Shift[]>({ default: [] });

const shiftsCount = ref(0);
function setShiftsCount() {
  let count = 0;
  caregiverShifts.value.forEach(shift => {
    if (shift.dayShift) count++;
    if (shift.nightShift) count++;
    if (shift.customShift) count++;
  });

  shiftsCount.value = count;
}

const emit = defineEmits(['input']);

function save() {
  caregiverShifts.value = [];
  for (let day = 1; day <= lastDay.value; day++) {
    const date = new Date(props.firstDay);
    date.setDate(new Date(props.firstDay).getDate() + day - 1);
    caregiverShifts.value.push({
      day: date.toISOString().split('T')[0],
      dayShift: items.value.find((item) => item.shiftType === 'day')?.[`day${day}`] || false,
      nightShift: items.value.find((item) => item.shiftType === 'night')?.[`day${day}`] || false,
      customShift: items.value.find((item) => item.shiftType === 'custom')?.[`day${day}`] || false,
    });
  }
  setShiftsCount();
  emit('input', caregiverShifts.value);
}

watch(items, () => {
  save();
}, { deep: true });

watch(() => props.firstDay, () => {
  headers.value = [{ title: 'Turno', value: 'name' }];
  generateDayHeaders();
  addDaysToItems();
}, { deep: true, immediate: true });

function isShiftTaken(dayNumber: number, index: number): boolean {
  const targetDate = new Date(props.firstDay);
  targetDate.setDate(targetDate.getDate() + dayNumber - 1);
  const blocked = props.blockedShifts.find(
    shift => new Date(shift.day.replace(/-/g, '/')).toDateString() === targetDate.toDateString(),
  );

  return !!blocked && (index === 0 ? blocked.dayShift : blocked.nightShift);
}
</script>
<template>
  <v-data-table
    :headers="headers"
    :items="items"
    hide-default-footer
  >
    <template
      v-for="day in lastDay"
      #[`item.day${day}`]="{ index, item }"
      :key="`${Math.random()}day${day}`"
    >
      <v-checkbox
        v-if="isShiftTaken(day, index)"
        :model-value="true"
        disabled
      />
      <v-checkbox
        v-else
        v-model="item[`day${day}`]"
        color="primary"
      />
    </template>
    <template #bottom>
      <v-row
        class="mt-4 ml-2 text-right"
        justify="space-between"
        align="center"
      >
        <p class="mx-4">
          {{ shiftsCount }} turnos asignados
        </p>
        <v-btn
          class="mr-4"
          variant="text"
          color="primary"
          append-icon="mdi-chevron-right"
          @click="nextWeek"
        >
          Siguiente semana
        </v-btn>
      </v-row>
    </template>
  </v-data-table>
</template>
