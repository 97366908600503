<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { caregiverDocumentsApi, type CaregiverDocument, type CaregiverDocumentForm } from '@/api/caregiver-documents';
import * as yup from 'yup';
import type { AxiosError } from 'axios';

const ONE_KB = 1024;
const ONE_MB = ONE_KB * ONE_KB;
const MAX_MB = 30;
const MAX_FILE_SIZE = MAX_MB * ONE_MB;

const { t } = useI18n();

interface Props {
  caregiverDocument?: CaregiverDocument | undefined;
  caregiverId: number;
  mode: 'new' | 'edit';
}

const props = defineProps<Props>();

const emit = defineEmits(['close']);

const validationSchema = yup.object({
  name: yup.string().required(t('caregiverDocumentForm.fieldRequired')),
  document: yup.mixed().when([], {
    is: () => props.mode === 'new',
    then: (schema) => schema.required(t('caregiverDocumentForm.fieldRequired')),
    otherwise: (schema) => schema.notRequired(),
  }).test(
    'is-valid-size',
    t('caregiverDocumentForm.fileSizeLimit'),
    (value) => value && value[0]?.size <= MAX_FILE_SIZE),
});

const initialValues = {
  caregiverId: props.caregiverId,
  name: props.caregiverDocument?.name,
};

const { handleSubmit, errors, defineField } = useForm<CaregiverDocumentForm>({ validationSchema, initialValues });

const [name, nameAttrs] = defineField('name');
const [document, documentAttrs] = defineField('document');

const valuesAsFormData = computed(() => {
  const formData = new FormData();
  formData.append('caregiver_document[caregiver_id]', props.caregiverId.toString());
  formData.append('caregiver_document[name]', name.value);
  formData.append('caregiver_document[document]', document.value instanceof File ? document.value : document.value[0]);
  if (props.caregiverDocument?.id) {
    formData.append('caregiver_document[id]', props.caregiverDocument.id.toString());
  }

  return formData;
});

const queryClient = useQueryClient();

const { mutate: createCaregiverDocument, isError: isCreateError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => caregiverDocumentsApi.create(valuesAsFormData.value),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['caregiverDocuments', props.caregiverId] });
      emit('close');
    },
  },
);

const { mutate: updateCaregiverDocument, isError: isUpdateError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => caregiverDocumentsApi.update({ id: props.caregiverDocument?.id, name: name.value }),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['caregiverDocuments', props.caregiverId] });
      emit('close');
    },
  },
);

const onSubmit = handleSubmit(() => {
  if (props.mode === 'edit') {
    updateCaregiverDocument();
  } else {
    createCaregiverDocument();
  }
});
</script>
<template>
  <v-card class="px-4 pb-4">
    <v-card-title class="mb-4 align-baseline d-flex">
      {{ mode === 'new' ? t('caregiverDocumentForm.createTitle') : t('caregiverDocumentForm.updateTitle') }}
      <v-spacer />
      <v-btn
        icon="mdi-window-close"
        variant="plain"
        @click="emit('close')"
      />
    </v-card-title>
    <v-form
      class="w-100 ma-0"
      @submit.prevent="onSubmit"
    >
      <v-text-field
        v-model="name"
        v-bind="nameAttrs"
        variant="outlined"
        class="px-2"
        :label="t('caregiverDocument.name')"
        :error-messages="errors.name"
      />
      <v-file-input
        v-if="mode === 'new'"
        v-model="document"
        v-bind="documentAttrs"
        class="px-2"
        variant="outlined"
        :label="t('caregiverDocument.document')"
        :error-messages="errors.document"
      />
      <v-row>
        <v-spacer />
        <v-btn
          color="primary"
          type="submit"
          class="mb-4 mr-4"
        >
          {{ mode === 'new' ? t('caregiverDocumentForm.createSubmit') : t('caregiverDocumentForm.updateSubmit') }}
        </v-btn>
      </v-row>
    </v-form>
    <v-alert
      v-if="isCreateError || isUpdateError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ t('userSession.defaultError') }}
    </v-alert>
  </v-card>
</template>
