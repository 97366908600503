import type { AxiosHeaders } from 'axios';
import { api } from './index';
import { type Caregiver } from './caregivers';
import { type Patient } from './patients';
import { type PaymentMovement } from './payment-movements';

const BASE_PATH = '/api/internal/payments';

export interface ShiftSummary {
  shiftsCount: number;
  shiftsCountWithTaxes: number;
  cost: number;
  taxes: number;
  totalNetCost: number;
  totalNetTaxes: number;
  totalGrossAmount: number;
}

export interface PaymentSummary {
  patient: Patient;
  shiftType: string;
  shiftsSummary: Array<ShiftSummary>;
}

export type Payment = {
  caregiver: Caregiver;
  shiftsCount: number;
  shiftsCountWithTaxes: number;
  cost: number;
  taxes: number;
  totalCost: number;
  paymentsSummary: Array<PaymentSummary>;
  lastEmailSentAt: string;
  paymentMovements: Array<PaymentMovement>;
  totalPaymentMovements: number;
  amountPendingToPay: number;
}

export interface PDFPayment {
  headers: AxiosHeaders;
  data: Blob;
}

export const paymentsApi = {
  index(queryParams: string): Promise<Array<Payment>> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?${queryParams}`,
    }).then(response => response.data.payments);
  },
  show(id: number, queryParams: string, format: string): Promise<PDFPayment> {
    return api({
      method: 'get',
      url: `${BASE_PATH}/${id}.${format}?${queryParams}`,
      responseType: format === 'pdf' ? 'blob' : 'json',
    });
  },
};
