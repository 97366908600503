<script setup lang="ts">
import { formatCurrency } from '@/utils/currency';
import { type Charge, type ChargeSummary, type ShiftSummary } from '@/api/charges';
import ChargeMovementForm from '@/components/charges/charge-movement-form.vue';
import ChargeMovementDestroyForm from '@/components/charges/charge-movement-destroy-form.vue';

interface Props {
  charge: Charge;
}

const props = defineProps<Props>();

const emit = defineEmits(['close']);

interface PatientDetailRow {
  caregiver: string;
  shiftType: string;
  shiftsCount: number;
  price: number;
  totalPrice: number;
}

function generatePatientDetailRows(patientCharges: Charge) {
  const rows: Array<PatientDetailRow> = [];
  patientCharges.chargesSummary.forEach((charge: ChargeSummary) => {
    charge.shiftsSummary.forEach((shifts: ShiftSummary) => {
      rows.push({
        caregiver: charge.caregiver.fullName,
        shiftType: charge.shiftType,
        shiftsCount: shifts.shiftsCount,
        price: shifts.price,
        totalPrice: shifts.totalPrice,
      });
    });
  }, []);

  return rows;
}

const headers = [
  { title: 'Profesional', value: 'caregiver' },
  { title: 'Tipo de Turno', value: 'shiftType' },
  { title: 'Cantidad de Turnos', value: 'shiftsCount' },
  { title: 'Precio por Turno', value: 'price' },
  { title: 'Total', value: 'totalPrice' },
];

const chargeMovementHeaders = [
  { title: 'Fecha', value: 'date' },
  { title: 'Monto', value: 'amount' },
  { title: 'Descripción', value: 'description' },
  { title: 'Acciones', value: 'actions' },
];

function localizedDate(date: string) {
  return date.split('-').reverse().join('/');
}

function shiftTypeText(shiftType: string) {
  if (shiftType === 'weekday') return 'Semana';
  if (shiftType === 'weekend') return 'Fin de Semana';
  if (shiftType === 'holiday') return 'Feriado';
  if (shiftType === 'inalienable_holiday') return 'Feriado Irrenunciable';

  return '';
}
</script>
<template>
  <v-card class="pr-8">
    <v-card-title class="align-baseline d-flex">
      {{ `Detalles del Cobro a ${props.charge.patient.fullName}` }}
      <v-spacer />
      <v-btn
        icon="mdi-window-close"
        variant="plain"
        @click="emit('close')"
      />
    </v-card-title>
    <v-expansion-panels class="ma-4">
      <v-expansion-panel>
        <v-expansion-panel-title class="font-weight-bold">
          Turnos
          <v-spacer />
          <v-chip
            color="green-darken-3"
            class="mr-8"
          >
            {{ formatCurrency(props.charge.price) }}
          </v-chip>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-data-table
            :headers="headers"
            :header-props="{ class: 'font-weight-bold' }"
            :items="generatePatientDetailRows(props.charge)"
            :items-per-page="-1"
            class="elevation-1"
          >
            <template #item.shiftType="{ item }">
              {{ shiftTypeText(item.shiftType) }}
            </template>
            <template #item.price="{ item }">
              {{ formatCurrency(item.price) }}
            </template>
            <template #item.totalPrice="{ item }">
              <v-chip color="primary">
                {{ formatCurrency(item.totalPrice) }}
              </v-chip>
            </template>
            <template #body.append>
              <tr>
                <th>-</th>
                <th>-</th>
                <th class="font-weight-bold">
                  {{ props.charge.shiftsCount }}
                </th>
                <th>-</th>
                <th>
                  <v-chip color="green-darken-3">
                    {{ formatCurrency(props.charge.price) }}
                  </v-chip>
                </th>
              </tr>
            </template>
            <template #bottom />
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel class="mt-4">
        <v-expansion-panel-title class="font-weight-bold">
          Movimientos
          <v-spacer />
          <v-chip
            :color="props.charge.totalChargeMovements < 0 ? 'error' : 'green-darken-3'"
            class="mr-8"
          >
            {{ formatCurrency(props.charge.totalChargeMovements) }}
          </v-chip>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-data-table
            :headers="chargeMovementHeaders"
            :items="props.charge.chargeMovements"
            :header-props="{ class: 'font-weight-bold' }"
            :items-per-page="-1"
            class="elevation-1"
            no-data-text="No hay movimientos"
          >
            <template #top>
              <v-toolbar
                color="white"
                flat
              >
                <v-spacer />
                <v-dialog
                  max-width="800"
                  scrollable
                >
                  <template #activator="{ props: activatorProps }">
                    <v-btn
                      v-bind="activatorProps"
                      icon="mdi-plus"
                      color="primary"
                      size="small"
                      elevation="2"
                    />
                  </template>
                  <template #default="{ isActive }">
                    <charge-movement-form
                      :patient-id="props.charge.patient.id"
                      mode="new"
                      @close="isActive.value = false"
                    />
                  </template>
                </v-dialog>
              </v-toolbar>
            </template>
            <template #item.date="{ item }">
              {{ localizedDate(item.date) }}
            </template>
            <template #item.amount="{ item }">
              <v-chip :color="item.movementType === 'refund' ? 'error' : 'green-darken-3'">
                {{ formatCurrency(item.amount) }}
              </v-chip>
            </template>
            <template #item.actions="{ item }">
              <v-dialog
                max-width="800"
                scrollable
              >
                <template #activator="{ props: activatorProps }">
                  <v-btn
                    v-bind="activatorProps"
                    icon="mdi-pencil"
                    size="small"
                    class="me-2"
                  />
                </template>
                <template #default="{ isActive }">
                  <charge-movement-form
                    :charge-movement="item"
                    :patient-id="props.charge.patient.id"
                    mode="edit"
                    @close="isActive.value = false"
                  />
                </template>
              </v-dialog>
              <v-dialog
                max-width="800"
                scrollable
              >
                <template #activator="{ props: activatorProps }">
                  <v-btn
                    v-bind="activatorProps"
                    icon="mdi-delete"
                    size="small"
                    class="me-2"
                  />
                </template>

                <template #default="{ isActive }">
                  <charge-movement-destroy-form
                    :charge-movement-id="item.id"
                    @close="isActive.value = false"
                  />
                </template>
              </v-dialog>
            </template>
            <template #body.append>
              <tr>
                <th class="font-weight-bold">
                  Total
                </th>
                <th>
                  <v-chip :color="props.charge.totalChargeMovements < 0 ? 'error' : 'green-darken-3'">
                    {{ formatCurrency(props.charge.totalChargeMovements) }}
                  </v-chip>
                </th>
                <th>-</th>
                <th>-</th>
              </tr>
            </template>
            <template #bottom />
          </v-data-table>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        class="mt-4"
        readonly
      >
        <v-expansion-panel-title
          class="font-weight-bold"
          expand-icon=""
        >
          Total a Cobrar
          <v-spacer />
          <v-chip color="primary mr-8">
            {{ formatCurrency(props.charge.amountPendingToCharge) }}
          </v-chip>
        </v-expansion-panel-title>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
