import { api } from './index';

const BASE_PATH = '/api/internal/caregivers';

export type Caregiver = {
  id: number;
  idCode: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryId: string;
  countryName: string;
  address: string;
  dateOfBirth: Date;
  humanDateOfBirth: string;
  description: string;
  age?: number;
  dni: string;
  sex: string;
  humanSex: string;
  career: string;
  humanCareer: string;
  fullName: string;
  communeId: string;
  communeName: string;
  comments: string;
}

export interface CaregiverForm {
  id?: number;
  idCode?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  dateOfBirth: Date;
  description: string;
  dni: string;
  sex: string;
  career: string;
  countryId: string;
  communeId: string;
  comments: string;
}

export const caregiversApi = {
  index(scope: string, queryParams: string): Promise<Array<Caregiver>> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?scope=${scope}&${queryParams}`,
    }).then(response => response.data.caregivers);
  },
  create(caregiverForm: CaregiverForm): Promise<Caregiver> {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: { caregiver: caregiverForm },
    }).then(response => response.data.caregiver);
  },
  update(caregiverForm: Partial<CaregiverForm>): Promise<Caregiver> {
    return api({
      method: 'put',
      url: `${BASE_PATH}/${caregiverForm.id}`,
      data: { caregiver: caregiverForm },
    }).then(response => response.data.caregiver);
  },
  destroy(id: number): Promise<void> {
    return api({
      method: 'delete',
      url: `${BASE_PATH}/${id}`,
    }).then(() => {});
  },
};
