<script setup lang="ts">
import { computed, inject, onMounted, ref, watch, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useFieldArray, useForm } from 'vee-validate';
import { useMutation } from '@tanstack/vue-query';
import * as yup from 'yup';
import * as rutHelpers from 'rut-helpers';
import { Loader } from '@googlemaps/js-api-loader';
import { patientsApi, type PatientForm } from '@/api/patients';
import { type SerializedUser } from '@/api/users';
import type { AxiosError } from 'axios';
import GoBackButton from '@/components/go-back-button.vue';
import { isNumber } from '@/utils/number-functions';

const { t } = useI18n();

const currentUser = inject<Ref<SerializedUser>>('currentUser');

interface Props {
  patient: PatientForm | undefined;
  mode: 'new' | 'edit';
}

const props = defineProps<Props>();

interface RepresentativeForm {
  id: number | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  dni: string | undefined;
  _destroy: boolean;
}

const blankRepresentative: RepresentativeForm = {
  id: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  phoneNumber: undefined,
  dni: undefined,
  _destroy: false,
};

const validationSchema = yup.object({
  firstName: yup.string().required(t('patientForm.fieldRequired')),
  lastName: yup.string().required(t('patientForm.fieldRequired')),
  dni: yup.string().nullable()
    .test('valid-dni', t('patientForm.invalidDni'), (value) => (value ? rutHelpers.rutValidate(value) : true)),
  representativesAttributes: yup.array().min(1, t('patientForm.atLeastOneRepresentative')).of(
    yup.object({
      firstName: yup.string().required(t('patientForm.fieldRequired')),
      lastName: yup.string().required(t('patientForm.fieldRequired')),
      email: yup.string().email(t('patientForm.emailFormat')).nullable(),
      dni: yup.string().nullable()
        .test('valid-dni', t('patientForm.invalidDni'), (value) => (value ? rutHelpers.rutValidate(value) : true)),
    }),
  ),
});

const initialValues = { ...props.patient } || {
  firstName: undefined,
  lastName: undefined,
  nickname: undefined,
  dni: undefined,
  address: undefined,
  latitude: undefined,
  longitude: undefined,
  weight: undefined,
  birthdate: undefined,
  sex: undefined,
  medicalHistory: undefined,
  surgicalHistory: undefined,
  technicalAids: undefined,
  insurances: undefined,
  representativesAttributes: [
    {
      id: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phoneNumber: undefined,
      dni: undefined,
      _destroy: false,
    },
  ],
};

const { handleSubmit, values, errors, defineField } = useForm<PatientForm>({ validationSchema, initialValues });

const [idCode, idCodeAttrs] = defineField('idCode');
const [firstName, firstNameAttrs] = defineField('firstName');
const [lastName, lastNameAttrs] = defineField('lastName');
const [nickname, nicknameAttrs] = defineField('nickname');
const [dni, dniAttrs] = defineField('dni');
const [address, addressAttrs] = defineField('address');
const [latitude, ,] = defineField('latitude');
const [longitude, ,] = defineField('longitude');
const [birthdate, birthdateAttrs] = defineField('birthdate');
const [weight, weightAttrs] = defineField('weight');
const [sex, sexAttrs] = defineField('sex');
const [medicalHistory, medicalHistoryAttrs] = defineField('medicalHistory');
const [surgicalHistory, surgicalHistoryAttrs] = defineField('surgicalHistory');
const [technicalAids, technicalAidsAttrs] = defineField('technicalAids');
const [insurances, insurancesAttrs] = defineField('insurances');
const [comments, commentsAttrs] = defineField('comments');
const { remove, push, fields: representatives } = useFieldArray<RepresentativeForm>('representativesAttributes');

watch(dni, (value) => {
  dni.value = rutHelpers.rutFormat(value);
});

function removeRepresentative(index: number) {
  if (representatives.value[index].value.id) {
    representatives.value[index].value._destroy = true; // eslint-disable-line no-underscore-dangle
  } else {
    remove(index);
  }
}

watch(representatives, (value) => {
  value.forEach((representative) => {
    if (representative.value.dni) {
      representative.value.dni = rutHelpers.rutFormat(representative.value.dni);
    }
  });
}, { deep: true });

watch(representatives, (value) => {
  if (value.length === 0) {
    push({ ...blankRepresentative });
  }
}, { immediate: true });

const { mutate: createPatient, isError: isCreateError, error: createError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => patientsApi.create({ ...values }),
    onSuccess: () => { window.location.href = '/patients'; },
  },
);

const { mutate: updatePatient, isError: isUpdateError, error: updateError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => patientsApi.update({ ...values, id: props.patient?.id }),
    onSuccess: () => { window.location.href = `/patients/${props.patient?.id}`; },
  },
);

const onSubmit = handleSubmit(() => {
  if (props.mode === 'edit') {
    updatePatient();
  } else {
    createPatient();
  }
});

const showMap = ref(false);
const loader = new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
});
const addressPosition = computed(() => ({ lat: parseFloat(latitude.value), lng: parseFloat(longitude.value) }));

onMounted(async () => {
  const places = await loader.importLibrary('places');
  const input = document.getElementById('address-input') as HTMLInputElement;
  if (!input) return;

  const options = { componentRestrictions: { country: 'cl' } };
  const autocomplete = new places.Autocomplete(input, options);

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    if (place.formatted_address) address.value = place.formatted_address;
    if (place.geometry?.location) {
      latitude.value = place.geometry.location.lat().toString();
      longitude.value = place.geometry.location.lng().toString();
    }
  });
});

async function createMap() {
  const { Map } = await loader.importLibrary('maps');
  const mapElement = document.getElementById('map') as HTMLInputElement;

  return new Map(mapElement, {
    center: addressPosition.value,
    zoom: 18,
    mapId: import.meta.env.VITE_GOOGLE_MAPS_MAP_ID,
  });
}

async function createMarker(map: google.maps.Map) {
  const { AdvancedMarkerElement } = await loader.importLibrary('marker');

  return new AdvancedMarkerElement({
    map,
    position: addressPosition.value,
  });
}

async function createGeocoder() {
  const { Geocoder } = await loader.importLibrary('geocoding');

  return new Geocoder();
}

async function updateAddressWithReverseGeocode(position: google.maps.LatLng) {
  const geocoder = await createGeocoder();
  geocoder.geocode(
    { location: position },
    (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
      if (status === 'OK' && results && results[0]) {
        address.value = results[0].formatted_address;
      }
    },
  );
}

async function updateMarkerPosition(
  event: google.maps.MapMouseEvent, marker: google.maps.marker.AdvancedMarkerElement,
) {
  const newPosition = event.latLng;
  if (newPosition) {
    marker.position = newPosition;
    latitude.value = newPosition.lat().toString();
    longitude.value = newPosition.lng().toString();
    await updateAddressWithReverseGeocode(newPosition);
  }
}

async function setupMap() {
  const map = await createMap();
  const marker = await createMarker(map);

  map.addListener('click', (event: google.maps.MapMouseEvent) => updateMarkerPosition(event, marker));
}

async function openMap() {
  showMap.value = true;
  await setupMap();
}

function errorMessage(error: AxiosError) {
  if (error.response?.data?.type === 'ActiveRecord::RecordNotUnique') {
    return t('patientForm.representativeRecordNotUnique');
  }

  return t('userSession.defaultError');
}
</script>
<template>
  <v-container class="v-col-12 v-col-md-8">
    <go-back-button />
    <v-card class="pa-4">
      <v-card-title class="mb-4">
        {{ mode === 'new' ? t('patientForm.createTitle') : t('patientForm.updateTitle') }}
      </v-card-title>
      <v-form
        class="w-100 ma-0"
        @submit.prevent="onSubmit"
      >
        <v-text-field
          v-if="currentUser?.companyUsesIdCode"
          v-model="idCode"
          v-bind="idCodeAttrs"
          type="number"
          variant="outlined"
          :label="`${t('patient.idCode')}`"
          :error-messages="errors.idCode"
          @wheel="$event.target.blur()"
          @keypress="isNumber($event)"
        />
        <v-text-field
          v-model="firstName"
          v-bind="firstNameAttrs"
          variant="outlined"
          :label="`${t('patient.firstName')} (requerido)`"
          :error-messages="errors.firstName"
        />
        <v-text-field
          v-model="lastName"
          v-bind="lastNameAttrs"
          variant="outlined"
          :label="`${t('patient.lastName')} (requerido)`"
          :error-messages="errors.lastName"
        />
        <v-text-field
          v-model="nickname"
          v-bind="nicknameAttrs"
          variant="outlined"
          :label="t('patient.nickname')"
          :error-messages="errors.nickname"
        />
        <v-text-field
          id="address-input"
          v-model="address"
          v-bind="addressAttrs"
          variant="outlined"
          :label="t('patient.address')"
          :error-messages="errors.address"
        >
          <template #append>
            <v-btn
              icon
              :disabled="!latitude || !longitude"
              @click="openMap"
            >
              <v-icon>mdi-map-marker</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <v-dialog
          v-model="showMap"
          max-width="800px"
        >
          <v-card class="pa-2">
            <v-card-title>{{ t('patientForm.addressMap') }}</v-card-title>
            <v-card-text>
              <div
                id="map"
                style="width: 100%; height: 400px;"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                @click="showMap = false"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-text-field
          v-model="birthdate"
          type="date"
          v-bind="birthdateAttrs"
          variant="outlined"
          :label="t('patient.birthdate')"
          :error-messages="errors.birthdate"
        />
        <v-text-field
          v-model="dni"
          v-bind="dniAttrs"
          variant="outlined"
          :label="t('patient.dni')"
          :error-messages="errors.dni"
        />
        <v-text-field
          v-model="weight"
          v-bind="weightAttrs"
          variant="outlined"
          :label="t('patient.weight')"
          :error-messages="errors.weight"
        />
        <v-radio-group
          v-model="sex"
          v-bind="sexAttrs"
          :label="t('patient.sex')"
          inline
        >
          <v-radio
            :label="t('patientForm.female')"
            value="female"
          />
          <v-radio
            :label="t('patientForm.male')"
            value="male"
          />
        </v-radio-group>
        <v-textarea
          v-model="medicalHistory"
          v-bind="medicalHistoryAttrs"
          variant="outlined"
          :label="t('patient.medicalHistory')"
          :error-messages="errors.medicalHistory"
        />
        <v-textarea
          v-model="surgicalHistory"
          v-bind="surgicalHistoryAttrs"
          variant="outlined"
          :label="t('patient.surgicalHistory')"
          :error-messages="errors.surgicalHistory"
        />
        <v-textarea
          v-model="technicalAids"
          v-bind="technicalAidsAttrs"
          variant="outlined"
          :label="t('patient.technicalAids')"
          :error-messages="errors.technicalAids"
        />
        <v-textarea
          v-model="insurances"
          v-bind="insurancesAttrs"
          variant="outlined"
          :label="t('patient.insurances')"
          :error-messages="errors.insurances"
        />
        <v-textarea
          v-model="comments"
          v-bind="commentsAttrs"
          variant="outlined"
          :label="t('patient.comments')"
          :error-messages="errors.comments"
        />
        <v-card class="pa-4">
          <v-card-title>{{ t('patient.representative') }}</v-card-title>
          <v-card
            v-for="(representative, index) in representatives"
            :key="representative.key"
            class="mt-4 pa-4"
          >
            <div v-if="representative.value._destroy === true">
              Al guardar el paciente se eliminará al apoderado
              {{ `${representative.value.firstName} ${representative.value.lastName}` }}.
              <v-btn
                color="primary"
                variant="outlined"
                size="small"
                class="ml-4"
                @click="representative.value._destroy = false"
              >
                Deshacer
              </v-btn>
            </div>
            <div v-else>
              <v-text-field
                v-model="representative.value.firstName"
                variant="outlined"
                :label="`${t('patient.representativeFirstName')} (requerido)`"
                :error-messages="errors[`representativesAttributes[${index}].firstName` as keyof typeof errors]"
              />
              <v-text-field
                v-model="representative.value.lastName"
                variant="outlined"
                :label="`${t('patient.representativeLastName')} (requerido)`"
                :error-messages="errors[`representativesAttributes[${index}].firstName` as keyof typeof errors]"
              />
              <v-text-field
                v-model="representative.value.email"
                variant="outlined"
                :label="t('patient.representativeEmail')"
                :error-messages="errors[`representativesAttributes[${index}].email` as keyof typeof errors]"
              />
              <v-text-field
                v-model="representative.value.phoneNumber"
                variant="outlined"
                :label="t('patient.representativePhoneNumber')"
                :error-messages="errors[`representativesAttributes[${index}].phoneNumber` as keyof typeof errors]"
              />
              <v-text-field
                v-model="representative.value.dni"
                variant="outlined"
                :label="t('patient.representativeDni')"
                :error-messages="errors[`representativesAttributes[${index}].dni` as keyof typeof errors]"
              />
              <v-btn
                v-if="index > 0"
                icon="mdi-delete"
                color="red"
                variant="outlined"
                size="small"
                class="me-2"
                @click="removeRepresentative(index)"
              />
            </div>
          </v-card>
          <v-btn
            icon="mdi-plus"
            color="primary"
            variant="outlined"
            size="small"
            elevation="2"
            class="mt-4"
            @click="push({ ...blankRepresentative })"
          />
        </v-card>
        <v-btn
          color="primary"
          type="submit"
          class="mt-4"
        >
          {{ mode === 'new' ? t('patientForm.createSubmit') : t('patientForm.updateSubmit') }}
        </v-btn>
      </v-form>
      <v-alert
        v-if="isCreateError && createError"
        class="mt-4 rounded"
        :type="'error'"
      >
        {{ errorMessage(createError) }}
      </v-alert>
      <v-alert
        v-if="isUpdateError && updateError"
        class="mt-4 rounded"
        :type="'error'"
      >
        {{ errorMessage(updateError) }}
      </v-alert>
    </v-card>
  </v-container>
</template>
