<script setup lang="ts">
import { computed, inject, ref, watch, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useMutation, useQuery } from '@tanstack/vue-query';
import * as yup from 'yup';
import { caregiversApi } from '@/api/caregivers';
import { patientsApi, type Patient } from '@/api/patients';
import { shiftsApi, type ShiftBatchCreateForm } from '@/api/shifts';
import type { AxiosError } from 'axios';
import { isNumber } from '@/utils/number-functions';
import { type SerializedUser } from '@/api/users';
import ShiftDaysSelectionForm from './shift-days-selection-form.vue';

const currentUser = inject<Ref<SerializedUser>>('currentUser');

interface Props {
  patient?: Patient;
}

const props = defineProps<Props>();

const { t } = useI18n();

const commonValidationSchema = {
  patientId: yup.number().required(t('shiftBatchCreateForm.fieldRequired')),
  startDate: yup.string().required(t('shiftBatchCreateForm.fieldRequired')),
  caregiversIds: yup.array().required(t('shiftBatchCreateForm.fieldRequired')),
  caregiversShifts: yup.array().required(t('shiftBatchCreateForm.fieldRequired')),
  withTaxBill: yup.boolean().required(t('shiftBatchCreateForm.fieldRequired')),
};

const normalValidationSchema = yup.object({
  ...commonValidationSchema,
  caregiverWeekShiftCost: yup.number().required(t('shiftBatchCreateForm.fieldRequired'))
    .typeError(t('shiftBatchCreateForm.fieldRequired')),
  caregiverWeekendShiftCost: yup.number().required(t('shiftBatchCreateForm.fieldRequired'))
    .typeError(t('shiftBatchCreateForm.fieldRequired')),
  weekShiftPrice: yup.number().required(t('shiftBatchCreateForm.fieldRequired'))
    .typeError(t('shiftBatchCreateForm.fieldRequired')),
  weekendShiftPrice: yup.number().required(t('shiftBatchCreateForm.fieldRequired'))
    .typeError(t('shiftBatchCreateForm.fieldRequired')),
});

const perHourValidationSchema = yup.object({
  ...commonValidationSchema,
  caregiverWeekShiftCostPerHour: yup.number().required(t('shiftBatchCreateForm.fieldRequired'))
    .typeError(t('shiftBatchCreateForm.fieldRequired')),
  caregiverWeekendShiftCostPerHour: yup.number().required(t('shiftBatchCreateForm.fieldRequired'))
    .typeError(t('shiftBatchCreateForm.fieldRequired')),
  weekShiftPricePerHour: yup.number().required(t('shiftBatchCreateForm.fieldRequired'))
    .typeError(t('shiftBatchCreateForm.fieldRequired')),
  weekendShiftPricePerHour: yup.number().required(t('shiftBatchCreateForm.fieldRequired'))
    .typeError(t('shiftBatchCreateForm.fieldRequired')),
});

const validationSchema = currentUser?.value.companyChargesAndPaysPerHours ?
  perHourValidationSchema : normalValidationSchema;

const commonInitialValues = {
  patientId: props.patient?.id,
  startDate: undefined,
  caregiversIds: [],
  caregiversShifts: [],
  withTaxBill: props.patient?.lastWeekShift?.withTaxBill || props.patient?.lastWeekendShift?.withTaxBill,
  startTime: undefined,
  endTime: undefined,
};

const normalInitialValues = {
  ...commonInitialValues,
  caregiverWeekShiftCost: props.patient?.lastWeekShift?.caregiverCost,
  caregiverWeekendShiftCost: props.patient?.lastWeekendShift?.caregiverCost,
  weekShiftPrice: props.patient?.lastWeekShift?.price,
  weekendShiftPrice: props.patient?.lastWeekendShift?.price,
};

const perHourInitialValues = {
  ...commonInitialValues,
  caregiverWeekShiftCostPerHour: props.patient?.lastWeekShift?.caregiverCostPerHour,
  caregiverWeekendShiftCostPerHour: props.patient?.lastWeekendShift?.caregiverCostPerHour,
  weekShiftPricePerHour: props.patient?.lastWeekShift?.pricePerHour,
  weekendShiftPricePerHour: props.patient?.lastWeekendShift?.pricePerHour,
};

const initialValues = currentUser?.value.companyChargesAndPaysPerHours ? perHourInitialValues : normalInitialValues;

const { handleSubmit, values, errors, defineField } = useForm<ShiftBatchCreateForm>(
  { validationSchema, initialValues },
);

const [patientId, patientIdAttrs] = defineField('patientId');
const [startDate, startDateAttrs] = defineField('startDate');
const [caregiverWeekShiftCost, caregiverWeekShiftCostAttrs] = defineField('caregiverWeekShiftCost');
const [caregiverWeekendShiftCost, caregiverWeekendShiftCostAttrs] = defineField('caregiverWeekendShiftCost');
const [weekShiftPrice, weekShiftPriceAttrs] = defineField('weekShiftPrice');
const [weekendShiftPrice, weekendShiftPriceAttrs] = defineField('weekendShiftPrice');
const [caregiverWeekShiftCostPerHour, caregiverWeekShiftCostPerHourAttrs] = defineField(
  'caregiverWeekShiftCostPerHour');
const [caregiverWeekendShiftCostPerHour, caregiverWeekendShiftCostPerHourAttrs] = defineField(
  'caregiverWeekendShiftCostPerHour');
const [weekShiftPricePerHour, weekShiftPricePerHourAttrs] = defineField('weekShiftPricePerHour');
const [weekendShiftPricePerHour, weekendShiftPricePerHourAttrs] = defineField('weekendShiftPricePerHour');
const [caregiversIds, caregiversIdsAttrs] = defineField('caregiversIds');
const [caregiversShifts] = defineField('caregiversShifts');
const [withTaxBill, withTaxBillAttrs] = defineField('withTaxBill');
const [startTime, startTimeAttrs] = defineField('startTime');
const [endTime, endTimeAttrs] = defineField('endTime');

watch(caregiversIds, () => {
  caregiversShifts.value.push([]);
});

interface Shift {
  day: string;
  dayShift: boolean;
  nightShift: boolean;
}

function updateCaregiverShifts(index: number, newShifts: Array<Shift>) {
  caregiversShifts.value.splice(index, 1, newShifts);
}

const { data: patients } = useQuery({
  queryKey: ['patients'],
  queryFn: () => patientsApi.index(),
});

const { data: caregivers } = useQuery({
  queryKey: ['caregivers'],
  queryFn: () => caregiversApi.index(),
});

const { mutate: createShifts, isError: isCreateError, isIdle, isPending, isSuccess } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => shiftsApi.create({ ...values }),
  },
);

watch(isSuccess, async () => {
  if (isSuccess.value === true) {
    const waitMs = 1500;
    await new Promise((resolve) => setTimeout(resolve, waitMs));
    window.location.href = `/shifts?patient_id=${patientId.value}`;
  }
});

const onSubmit = handleSubmit(() => {
  createShifts();
});

const formattedStartDate = computed(() => startDate.value.replace(/-/g, '/'));

function getCaregiverName(caregiverId: number) {
  if (caregivers.value === undefined) return '';
  const caregiver = caregivers.value.find(c => c.id === caregiverId);

  return caregiver ? caregiver.fullName : '';
}

const steps = computed(() => {
  const stepsArray = [t('shiftBatchCreateForm.stepOneTitle'), t('shiftBatchCreateForm.stepTwoTitle')];
  caregiversIds.value?.forEach((caregiverId: number) => {
    stepsArray.push(`Turnos ${getCaregiverName(caregiverId)}`);
  });
  stepsArray.push(t('shiftBatchCreateForm.stepThreeTitle'));

  return stepsArray;
});

function getBlockedShifts(caregiverIndex: number) {
  return caregiversShifts.value.reduce((acc, caregiverShifts, currentIndex) => {
    if (caregiverIndex === currentIndex) return acc;

    caregiverShifts.forEach((shift, index) => {
      if (acc[index]) {
        acc[index].dayShift = acc[index].dayShift || shift.dayShift;
        acc[index].nightShift = acc[index].nightShift || shift.nightShift;
      } else {
        acc[index] = { day: shift.day, dayShift: shift.dayShift, nightShift: shift.nightShift };
      }
    });

    return acc;
  }, []);
}

const shiftType = ref('dayAndNight');
const showDayAndNightShifts = ref(true);
const showCustomShifts = ref(false);
const showShifts = computed(() => [showDayAndNightShifts.value, showDayAndNightShifts.value, showCustomShifts.value]);

watch(shiftType, () => {
  showDayAndNightShifts.value = shiftType.value === 'dayAndNight';
  showCustomShifts.value = shiftType.value === 'custom';
});

function cleanShiftValues() {
  caregiverWeekShiftCost.value = undefined;
  weekShiftPrice.value = undefined;
  caregiverWeekendShiftCost.value = undefined;
  weekendShiftPrice.value = undefined;
  caregiverWeekShiftCostPerHour.value = undefined;
  weekShiftPricePerHour.value = undefined;
  caregiverWeekendShiftCostPerHour.value = undefined;
  weekendShiftPricePerHour.value = undefined;
  withTaxBill.value = false;
}
function setWeekShiftValues(patient: Patient) {
  caregiverWeekShiftCost.value = patient.lastWeekShift.caregiverCost;
  caregiverWeekShiftCostPerHour.value = patient.lastWeekShift.caregiverCostPerHour;
  weekShiftPrice.value = patient.lastWeekShift.price;
  weekShiftPricePerHour.value = patient.lastWeekShift.pricePerHour;
  withTaxBill.value = patient.lastWeekShift.withTaxBill;
}
function setWeekendShiftValues(patient: Patient) {
  caregiverWeekendShiftCost.value = patient.lastWeekendShift.caregiverCost;
  caregiverWeekendShiftCostPerHour.value = patient.lastWeekendShift.caregiverCostPerHour;
  weekendShiftPrice.value = patient.lastWeekendShift.price;
  weekendShiftPricePerHour.value = patient.lastWeekendShift.pricePerHour;
  withTaxBill.value = patient.lastWeekendShift.withTaxBill;
}
function prefillShiftValues() {
  if (patientId.value) {
    const patient = patients.value?.find(p => p.id === patientId.value);
    if (patient?.lastWeekShift) setWeekShiftValues(patient);
    if (patient?.lastWeekendShift) setWeekendShiftValues(patient);
  }
}

watch(patientId, () => {
  cleanShiftValues();
  prefillShiftValues();
});

function isFilled(value: boolean | string | number | null | undefined) {
  return (value !== '' && value !== null && value !== undefined);
}
function isTimeFilled(value: { hours: number, minutes: number } | null | undefined) {
  return (value !== null && value !== undefined);
}
const currentStep = ref(0);
const isFirstStepCompleted = computed(() =>
  isFilled(patientId.value) &&
  caregiversIds.value.length > 0 &&
  isFilled(startDate.value),
);
const isSecondStepCompleted = computed(() => {
  if (showCustomShifts.value) return isTimeFilled(startTime.value) && isTimeFilled(endTime.value);

  return true;
});
const isLastStepPerHourCompleted = computed(() =>
  isFilled(caregiverWeekShiftCostPerHour.value) &&
  isFilled(caregiverWeekendShiftCostPerHour.value) &&
  isFilled(weekShiftPricePerHour.value) &&
  isFilled(weekendShiftPricePerHour.value) &&
  isFilled(withTaxBill.value),
);
const isLastStepCompleted = computed(() => {
  if (currentUser?.value.companyChargesAndPaysPerHours) return isLastStepPerHourCompleted.value;

  return isFilled(caregiverWeekShiftCost.value) &&
    isFilled(caregiverWeekendShiftCost.value) &&
    isFilled(weekShiftPrice.value) &&
    isFilled(weekendShiftPrice.value) &&
    isFilled(withTaxBill.value);
});
// eslint-disable-next-line complexity
const canGoToNextStep = computed(() => {
  if (currentStep.value === 0 && !isFirstStepCompleted.value) return false;
  if (currentStep.value === 1 && !isSecondStepCompleted.value) return false;
  if (currentStep.value === steps.value.length - 1 && !isLastStepCompleted.value) return false;

  return true;
});
</script>
<template>
  <v-card
    class="v-col-12 v-col-md-10 pa-4"
    flat
  >
    <v-card-title class="mb-4">
      {{ t('shiftBatchCreateForm.title') }}
    </v-card-title>
    <v-form
      v-if="isIdle"
      class="w-100 ma-0"
      @submit.prevent="onSubmit"
    >
      <v-stepper v-model="currentStep">
        <template #default="{ prev, next }">
          <v-stepper-header>
            <template
              v-for="(step, index) in steps"
              :key="`${step}-step`"
            >
              <v-stepper-item
                :step="step"
                :value="index"
                icon="mdi-form-select"
                :title="steps[index]"
                :complete="index < currentStep"
                :color="index <= currentStep ? 'primary' : 'gray'"
              />

              <v-divider
                v-if="index !== steps.length - 1"
                :key="index"
              />
            </template>
          </v-stepper-header>

          <v-stepper-window>
            <v-stepper-window-item>
              <v-card flat>
                <v-card-subtitle class="mb-6 text-black text-subtitle-1">
                  {{ t('shiftBatchCreateForm.stepOneSubtitle') }}
                </v-card-subtitle>
                <v-autocomplete
                  v-model="patientId"
                  v-bind="patientIdAttrs"
                  variant="outlined"
                  item-title="fullName"
                  item-value="id"
                  :label="t('shift.patient')"
                  :error-messages="errors.patientId"
                  :items="patients"
                />
                <v-autocomplete
                  v-model="caregiversIds"
                  v-bind="caregiversIdsAttrs"
                  variant="outlined"
                  item-title="fullName"
                  item-value="id"
                  :label="t('shiftBatchCreateForm.caregivers')"
                  :error-messages="errors.caregiversIds"
                  :items="caregivers"
                  chips
                  multiple
                  clear-on-select
                />
                <v-text-field
                  v-model="startDate"
                  type="date"
                  v-bind="startDateAttrs"
                  variant="outlined"
                  hint="A partir de esta fecha asignarás los turnos."
                  persistent-hint
                  :label="t('shift.startDate')"
                  :error-messages="errors.startDate"
                />
              </v-card>
            </v-stepper-window-item>
            <v-stepper-window-item>
              <v-card flat>
                <v-card-subtitle class="mb-6 text-black text-subtitle-1">
                  {{ t('shiftBatchCreateForm.stepTwoSubtitle') }}
                </v-card-subtitle>
                <v-radio-group v-model="shiftType">
                  <v-radio
                    class="pa-4"
                    label="Turnos Día (8:00 a 20:00) y Noche (20:00 a 8:00)"
                    value="dayAndNight"
                  />
                  <v-radio
                    class="pa-4"
                    label="Turnos Personalizados"
                    value="custom"
                  />
                </v-radio-group>
                <div v-if="showCustomShifts">
                  <vue-date-picker
                    v-model="startTime"
                    v-bind="startTimeAttrs"
                    class="w-50 pa-4"
                    time-picker
                    auto-apply
                    minutes-increment="15"
                    minutes-grid-increment="15"
                    placeholder="Hora Inicio"
                    :teleport="true"
                  />
                  <vue-date-picker
                    v-model="endTime"
                    v-bind="endTimeAttrs"
                    class="w-50 pa-4"
                    time-picker
                    auto-apply
                    minutes-increment="15"
                    minutes-grid-increment="15"
                    placeholder="Hora Término"
                    :teleport="true"
                  />
                </div>
              </v-card>
            </v-stepper-window-item>
            <v-stepper-window-item
              v-for="(caregiverId, index) in caregiversIds"
              :key="`caregiver-step-${index}`"
            >
              <v-card
                :title="`Turnos ${getCaregiverName(caregiverId)}`"
                flat
              >
                <shift-days-selection-form
                  :first-day="formattedStartDate"
                  :caregiver-id="caregiverId"
                  :blocked-shifts="getBlockedShifts(index)"
                  :show-shifts="showShifts"
                  class="mb-6"
                  :value="caregiversShifts[index]"
                  @input="updateCaregiverShifts(index, $event)"
                />
              </v-card>
            </v-stepper-window-item>
            <v-stepper-window-item>
              <v-card flat>
                <v-card-subtitle class="mb-6 text-black text-subtitle-1">
                  {{ t('shiftBatchCreateForm.stepThreeSubtitle') }}
                </v-card-subtitle>
                <v-card
                  :title="t('shiftBatchCreateForm.priceTitle')"
                  flat
                >
                  <v-row
                    v-if="currentUser?.companyChargesAndPaysPerHours"
                    class="ma-2"
                  >
                    <v-text-field
                      v-model="weekShiftPricePerHour"
                      v-bind="weekShiftPricePerHourAttrs"
                      type="number"
                      prefix="$"
                      variant="outlined"
                      :label="t('shiftBatchCreateForm.weekShiftPricePerHour')"
                      :error-messages="errors.weekShiftPricePerHour"
                      @wheel="$event.target.blur()"
                      @keypress="isNumber($event)"
                    />
                    <v-text-field
                      v-model="weekendShiftPricePerHour"
                      v-bind="weekendShiftPricePerHourAttrs"
                      type="number"
                      class="ml-4"
                      prefix="$"
                      variant="outlined"
                      :label="t('shiftBatchCreateForm.weekendShiftPricePerHour')"
                      :error-messages="errors.weekendShiftPricePerHour"
                      @wheel="$event.target.blur()"
                      @keypress="isNumber($event)"
                    />
                  </v-row>
                  <v-row
                    v-else
                    class="ma-2"
                  >
                    <v-text-field
                      v-model="weekShiftPrice"
                      v-bind="weekShiftPriceAttrs"
                      type="number"
                      prefix="$"
                      variant="outlined"
                      :label="t('shiftBatchCreateForm.weekShiftPrice')"
                      :error-messages="errors.weekShiftPrice"
                      @wheel="$event.target.blur()"
                      @keypress="isNumber($event)"
                    />
                    <v-text-field
                      v-model="weekendShiftPrice"
                      v-bind="weekendShiftPriceAttrs"
                      type="number"
                      class="ml-4"
                      prefix="$"
                      variant="outlined"
                      :label="t('shiftBatchCreateForm.weekendShiftPrice')"
                      :error-messages="errors.weekendShiftPrice"
                      @wheel="$event.target.blur()"
                      @keypress="isNumber($event)"
                    />
                  </v-row>
                </v-card>
                <v-card
                  :title="t('shiftBatchCreateForm.caregiverCostTitle')"
                  flat
                >
                  <v-row
                    v-if="currentUser?.companyChargesAndPaysPerHours"
                    class="ma-2"
                  >
                    <v-text-field
                      v-model="caregiverWeekShiftCostPerHour"
                      v-bind="caregiverWeekShiftCostPerHourAttrs"
                      type="number"
                      prefix="$"
                      variant="outlined"
                      :label="t('shiftBatchCreateForm.caregiverWeekShiftCostPerHour')"
                      :error-messages="errors.caregiverWeekShiftCostPerHour"
                      @wheel="$event.target.blur()"
                      @keypress="isNumber($event)"
                    />
                    <v-text-field
                      v-model="caregiverWeekendShiftCostPerHour"
                      v-bind="caregiverWeekendShiftCostPerHourAttrs"
                      type="number"
                      class="ml-4"
                      prefix="$"
                      variant="outlined"
                      :label="t('shiftBatchCreateForm.caregiverWeekendShiftCostPerHour')"
                      :error-messages="errors.caregiverWeekendShiftCostPerHour"
                      @wheel="$event.target.blur()"
                      @keypress="isNumber($event)"
                    />
                  </v-row>
                  <v-row
                    v-else
                    class="ma-2"
                  >
                    <v-text-field
                      v-model="caregiverWeekShiftCost"
                      v-bind="caregiverWeekShiftCostAttrs"
                      type="number"
                      prefix="$"
                      variant="outlined"
                      :label="t('shiftBatchCreateForm.caregiverWeekShiftCost')"
                      :error-messages="errors.caregiverWeekShiftCost"
                      @wheel="$event.target.blur()"
                      @keypress="isNumber($event)"
                    />
                    <v-text-field
                      v-model="caregiverWeekendShiftCost"
                      v-bind="caregiverWeekendShiftCostAttrs"
                      type="number"
                      class="ml-4"
                      prefix="$"
                      variant="outlined"
                      :label="t('shiftBatchCreateForm.caregiverWeekendShiftCost')"
                      :error-messages="errors.caregiverWeekendShiftCost"
                      @wheel="$event.target.blur()"
                      @keypress="isNumber($event)"
                    />
                  </v-row>
                  <v-radio-group
                    v-model="withTaxBill"
                    v-bind="withTaxBillAttrs"
                    :label="t('shiftBatchCreateForm.withTaxBillTitle')"
                    inline
                  >
                    <v-radio
                      :label="t('shiftBatchCreateForm.yes')"
                      :value="true"
                    />
                    <v-radio
                      :label="t('shiftBatchCreateForm.no')"
                      :value="false"
                    />
                  </v-radio-group>
                </v-card>
              </v-card>
            </v-stepper-window-item>
          </v-stepper-window>

          <v-stepper-actions
            :class="currentStep === 0 ? 'justify-end' : 'justify-between'"
            :disabled="canGoToNextStep ? false : 'next'"
          >
            <template #prev>
              <v-btn
                v-if="currentStep !== 0"
                @click="prev"
              >
                {{ t('shiftBatchCreateForm.previous') }}
              </v-btn>
            </template>
            <template #next>
              <v-btn
                v-if="currentStep !== steps.length - 1"
                class="align-self-end"
                color="primary"
                @click="next"
              >
                {{ t('shiftBatchCreateForm.next') }}
              </v-btn>
              <v-btn
                v-else
                variant="flat"
                color="primary"
                type="submit"
              >
                {{ t('shiftBatchCreateForm.submit') }}
              </v-btn>
            </template>
          </v-stepper-actions>
        </template>
      </v-stepper>
    </v-form>
    <v-card
      v-if="isPending"
      class="pa-16"
    >
      <v-col>
        <v-row class="justify-center d-flex">
          <v-progress-circular
            class="mx-auto"
            indeterminate
            color="primary"
          />
        </v-row>
        <v-row class="justify-center mt-8 d-flex">
          <p>Estamos creando los turnos, esto puede tomar unos segundos...</p>
        </v-row>
      </v-col>
    </v-card>
    <v-card
      v-if="isSuccess"
      class="pa-16"
    >
      <v-col>
        <v-row class="justify-center d-flex">
          <v-icon
            icon="mdi-check-circle"
            color="primary"
            size="50"
          />
        </v-row>
        <v-row class="justify-center mt-8 d-flex">
          <p>Turnos creados exitosamente!</p>
        </v-row>
      </v-col>
    </v-card>
    <v-alert
      v-if="isCreateError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ t('userSession.defaultError') }}
    </v-alert>
  </v-card>
</template>
