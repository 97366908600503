<script setup lang="ts">
import { type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useMutation } from '@tanstack/vue-query';
import { caregiversApi, type Caregiver } from '@/api/caregivers';
import type { AxiosError } from 'axios';

const { t } = useI18n();

const emit = defineEmits(['close', 'refetch', 'success']);

const props = defineProps({
  caregiver: {
    type: Object as PropType<Caregiver>,
    required: true,
  },
  closeButtonText: {
    type: String,
    default: 'Cerrar',
  },
});

const { handleSubmit } = useForm();

const { mutate, isError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => caregiversApi.destroy(props.caregiver.id),
    onSuccess: () => {
      emit('refetch');
      emit('success');
      emit('close');
    },
  },
);

const onSubmit = handleSubmit(() => mutate());
</script>
<template>
  <v-card
    class="v-col-12 v-col-md-10 pa-4"
    flat
  >
    <v-card-title>
      {{ t('caregiverDiscardForm.title') }} {{ caregiver.fullName }}
    </v-card-title>
    <v-form
      class="w-100 ma-0"
      @submit.prevent="onSubmit"
    >
      <v-card flat>
        <v-card-text>
          {{ t('caregiverDiscardForm.confirmation') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            :text="props.closeButtonText"
            @click="$emit('close')"
          />
          <v-spacer />
          <v-btn
            color="red"
            type="submit"
          >
            {{ t('caregiverDiscardForm.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-alert
      v-if="isError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ t('userSession.defaultError') }}
    </v-alert>
  </v-card>
</template>
