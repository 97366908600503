<script setup lang="ts">
import { computed, inject, ref, watch, type Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { useFilters } from '@/composables/useFilters';
import { caregiversApi } from '@/api/caregivers';
import { communesApi } from '@/api/communes';
import { type SerializedUser } from '@/api/users';
import CaregiverDiscardForm from './caregiver-discard-form.vue';
import CaregiverUndiscardForm from './caregiver-undiscard-form.vue';

const headers = [
  { title: 'Nombre', value: 'firstName', sortable: true },
  { title: 'Apellido', value: 'lastName', sortable: true },
  { title: 'Teléfono', value: 'phoneNumber' },
  { title: 'Comuna', value: 'communeName' },
  { title: 'Especialidad', value: 'humanCareer', sortable: true },
  { title: 'Acciones', value: 'actions' },
];

const currentUser = inject<Ref<SerializedUser>>('currentUser');
if (currentUser?.value?.companyUsesIdCode) {
  headers.unshift({ title: 'Código', value: 'idCode', sortable: true });
}

const careerColors = {
  'caregiver': 'green',
  'nurse_technician': 'blue',
  'nurse': 'red-darken-2',
  'auxiliary_nurse': 'orange-darken-2',
  'occupational_therapist': 'yellow-darken-3',
  'kinesiologist': 'cyan',
  'phonoaudiologist': 'pink',
  'doctor': 'brown-darken-2',
  'podologist': 'green-darken-4',
};

function getCareerColor(career: keyof typeof careerColors) {
  if (careerColors[career]) return careerColors[career];

  return 'purple';
}

const filters = ref<{ communeIdEq: undefined | string }>({ communeIdEq: undefined });
const queryParams = useFilters(filters.value);

const { data: communes } = useQuery({
  queryKey: ['communes'],
  queryFn: () => communesApi.index('caregivers'),
});

const { data: activeCaregivers, refetch: refetchActiveCaregivers, isPending } = useQuery({
  queryKey: ['activeCaregivers'],
  queryFn: () => caregiversApi.index('active', queryParams.value),
});

const { data: discardedCaregivers, refetch: refetchDiscardedCaregivers } = useQuery({
  queryKey: ['discardedCaregivers'],
  queryFn: () => caregiversApi.index('discarded', queryParams.value),
});

const scope = ref('active');
const caregivers = computed(
  () => (scope.value === 'active' ? activeCaregivers.value : discardedCaregivers.value),
);

const actionSuccess = ref(false);

watch(filters, () => {
  refetchActiveCaregivers();
  refetchDiscardedCaregivers();
}, { deep: true });

const search = ref('');
</script>
<template>
  <v-card class="w-100">
    <v-data-table
      :headers="headers"
      :header-props="{ class: 'font-weight-bold' }"
      :items="caregivers"
      :search="search"
      :loading="isPending"
      loading-text="Cargando los profesionales..."
    >
      <template #top>
        <v-toolbar
          color="white"
          flat
        >
          <v-toolbar-title class="font-weight-bold">
            Profesionales
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar
          color="white"
          class="ml-2"
          flat
        >
          <v-tabs
            v-model="scope"
            color="primary"
          >
            <v-tab
              value="active"
              class="rounded-pill text-body-1"
            >
              Activos
            </v-tab>
            <v-tab
              value="discarded"
              class="rounded-pill text-body-1"
            >
              Archivados
            </v-tab>
          </v-tabs>
          <v-spacer />
          <v-text-field
            v-model="search"
            label="Buscar"
            class="mx-4 mb-4 v-col-3"
            prepend-inner-icon="mdi-magnify"
            single-line
            variant="underlined"
            hide-details
          />
          <v-autocomplete
            v-model="filters.communeIdEq"
            :items="communes"
            item-title="name"
            item-value="id"
            label="Comuna"
            class="pb-1 mr-4 v-col-2"
            single-line
            clearable
            variant="underlined"
          />
          <v-btn
            class="mb-2 text-white border bg-primary"
            href="caregivers/new"
          >
            Agregar
          </v-btn>
        </v-toolbar>
      </template>
      <template #item.humanCareer="{ item }">
        <v-chip :color="getCareerColor(item.career)">
          {{ item.humanCareer }}
        </v-chip>
      </template>
      <template #item.actions="{ item }">
        <div
          v-if="scope === 'active'"
          class="d-flex"
        >
          <v-btn
            icon="mdi-eye"
            size="small"
            class="me-2"
            :href="`/caregivers/${item.id}`"
          />
          <v-btn
            icon="mdi-pencil"
            size="small"
            class="me-2"
            :href="`/caregivers/${item.id}/edit`"
          />
          <v-dialog
            max-width="800"
            scrollable
          >
            <template #activator="{ props: activatorProps }">
              <v-btn
                v-bind="activatorProps"
                icon="mdi-archive"
                size="small"
              />
            </template>
            <template #default="{ isActive }">
              <caregiver-discard-form
                :caregiver="item"
                @close="isActive.value = false"
                @success="actionSuccess = true"
                @refetch="refetchActiveCaregivers(); refetchDiscardedCaregivers();"
              />
            </template>
          </v-dialog>
        </div>
        <v-dialog
          v-else-if="scope === 'discarded'"
          max-width="800"
          scrollable
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              v-bind="activatorProps"
              icon="mdi-package-up"
              size="small"
            />
          </template>
          <template #default="{ isActive }">
            <caregiver-undiscard-form
              :caregiver="item"
              @close="isActive.value = false"
              @success="actionSuccess = true"
              @refetch="refetchActiveCaregivers(); refetchDiscardedCaregivers();"
            />
          </template>
        </v-dialog>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="actionSuccess"
      :timeout="2000"
      color="success"
      rounded="pill"
    >
      <v-icon
        icon="mdi-check-circle"
        class="mr-4"
      />
      Cambios guardados exitosamente!
    </v-snackbar>
  </v-card>
</template>
